/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).on("scroll touchmove", function () {
            $('#header_nav').toggleClass('tiny', $(document).scrollTop() > 0);
        });

        var scrollnow = function(e) {
            // if scrollnow()-function was triggered by an event
            if (e) {
                e.preventDefault();
                var target = this.hash;
            }
            // else it was called when page with a #hash was loaded
            else {
                var target = location.hash;
            }

            // same page scroll
            $.smoothScroll({
                offset: -240,
                scrollTarget: target
            });
            $('.games-tab a').smoothScroll({
                exclude: ['#bs_first_tab17','#bs_tab145298083215517', '#bs_tab145298088315117', '#bs_tab145298095594617', '#bs_tab145298101853617', '#bs_tab6', '#bs_tab7']
            });
        };

        $('.nav-tabs li a').click(function(e){
          e.preventDefault();
          e.stopImmediatePropagation();
          $(this).tab('show');
        });

        // if page has a #hash
        if (location.hash) {
            $('html, body').scrollTop(0).show();
            // smooth-scroll to hash
            scrollnow();
        }

        // for each <a>-element that contains a "/" and a "#"
        $('a[href*="/"][href*=#]').each(function(){
            // if the pathname of the href references the same page
            if (this.pathname.replace(/^\//,'') === location.pathname.replace(/^\//,'') && this.hostname === location.hostname) {
                // only keep the hash, i.e. do not keep the pathname
                $(this).attr("href", this.hash);
            }
        });

        // select all href-elements that start with #
        // including the ones that were stripped by their pathname just above
        $('a[href^=#]:not([href=#])').click(scrollnow);


        // MODAL LIGHTBOX FOR VIDEO GALLERIES
        // delegate calls to data-toggle="lightbox"
        $(document).delegate('*[data-toggle="lightbox"]:not([data-gallery="navigateTo"])', 'click', function(event) {
            event.preventDefault();
            return $(this).ekkoLightbox({
                onShown: function() {
                    if (window.console) {
                        return console.log('Checking our the events huh?');
                    }
                }
            });
        });



        // ISOTOPE 
        $(window).load(function(){ 
          
            var $container = $('#iso-container'); 
            $container.isotope({ 
                filter: '*', 
                animationOptions: { 
                    duration: 750, 
                    easing: 'linear', 
                    queue: false, 
                } 
            }); 
          
            $('nav.primary ul a').click(function(e){ 
                var selector = $(this).attr('data-filter'); 
                $container.isotope({ 
                    filter: selector, 
                    animationOptions: { 
                        duration: 750, 
                        easing: 'linear', 
                        queue: false, 
                    } 
                }); 
                e.preventDefault();
                $('a').removeClass('active');
                $(this).addClass('active');
              return false; 
            }); 
          
        });



        var $optionSets = $('nav.primary ul'), 
         $optionLinks = $optionSets.find('a'); 
     
         $optionLinks.click(function(){ 
            var $this = $(this); 
        // don't proceed if already selected 
        if ( $this.hasClass('selected') ) { 
            return false; 
        } 
        var $optionSet = $this.parents('nav.primary ul'); 
           $optionSet.find('.selected').removeClass('selected'); 
           $this.addClass('selected');  
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired 
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

            
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



/*
*  render_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $el (jQuery element)
*  @return  n/a
*/

function render_map( $el ) {

  // var
  var $markers = $el.find('.marker');

  // vars
  var args = {
    zoom    : 16,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP
  };

  // create map           
  var map = new google.maps.Map( $el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function(){

      add_marker( $(this), map );

  });

  // center map
  center_map( map );

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $marker (jQuery element)
*  @param map (Google Map object)
*  @return  n/a
*/

function add_marker( $marker, map ) {

  // var
  var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

  // create marker
  var marker = new google.maps.Marker({
    position  : latlng,
    map     : map
  });

  // add to array
  map.markers.push( marker );

  // if marker contains HTML, add it to an infoWindow
  if( $marker.html() )
  {
    // create info window
    var infowindow = new google.maps.InfoWindow({
      content   : $marker.html()
    });

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function() {

      infowindow.open( map, marker );

    });
  }

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param map (Google Map object)
*  @return  n/a
*/

function center_map( map ) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each( map.markers, function( i, marker ){

    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

    bounds.extend( latlng );

  });

  // only 1 marker?
  if( map.markers.length === 1 )
  {
    // set center of map
      map.setCenter( bounds.getCenter() );
      map.setZoom( 16 );
  }
  else
  {
    // fit to bounds
    map.fitBounds( bounds );
  }

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type  function
*  @date  8/11/2013
*  @since 5.0.0
*
*  @param n/a
*  @return  n/a
*/

$(document).ready(function(){

  $('.acf-map').each(function(){

    render_map( $(this) );

  });


});



/*==============================================================================
    Set up
==============================================================================*/
   // Give first items emphasis classes
   $(".carousel-inner .item:first").addClass("active");
   $("#thumb-carousel li:first").addClass("selected");
   $("#thumb-carousel .item:first").addClass("active");

   // Prevent auto play
   $('#myCarousel').carousel({
     interval: false
   });
   $('#thumb-carousel').carousel({
     interval: false
   });


/*==============================================================================
    Listen to the 'slid carousel' event to trigger code after each slide change
==============================================================================*/

  $('#myCarousel').on('slid.bs.carousel', function () {

    // Useful variables
    var carouselData = $(this).data('bs.carousel');
    var currentIndex = carouselData.getActiveIndex();
    var total = carouselData.$items.length;

    // Create the text we want to display.
    // We increment the index because humans don't count like machines
    //var text = (currentIndex + 1) + " of " + total;
    // You have to create a HTML element <div id="carousel-index"></div>
    // under your carousel to make this work
    //$('#carousel-index').text(text);

    $('[id^=carousel-selector-]').removeClass('selected');
    $('[id^=carousel-selector-'+(currentIndex)+']').addClass('selected');

    // slide to the thumbnail slider item that contains the current myCarousel slide

    /*========================================================================*/
    // Remove active class from #item-x
    //$('[id^=item-]').removeClass('active');
    // Which item contains the current thumbnail
    //$( "[id^=item-]" ).has("[id^=carousel-selector-"+(currentIndex)+"]").addClass( "active" );

    /*========================================================================*/

    var targetItem = $( "[id^=item-]" ).has("[id^=carousel-selector-"+(currentIndex)+"]");
    var id_selector = $(targetItem).attr("id");
    var id = id_selector.substr(id_selector.length -1);
    id = parseInt(id, 10);
    id = (id -1);

    // Create a HTML element <div id="carousel-index"></div>
    $('#carousel-index').text(id);


    //if (( '#thumb-carousel .active' ).has("[id^=carousel-selector-"+(currentIndex)+"]")){}
    //else {$('#thumb-carousel').carousel( id );}// force slide to this item
    $('#thumb-carousel').carousel( id );

/*==============================================================================
    Hiding Prev & Next Links at extremes
==============================================================================*/
    checkitem = function() {
    var $this;
    $this = $("#myCarousel");
    if ($("#myCarousel .carousel-inner .item:first").hasClass("active")) {
      $this.children(".left").hide();
      $this.children(".right").show();
    } else if ($("#myCarousel .carousel-inner .item:last").hasClass("active")) {
      $this.children(".right").hide();
      $this.children(".left").show();
    } else {
      $this.children(".carousel-control").show();
    }
  };

  checkitem();

  $("#myCarousel").on("slid.bs.carousel", "", checkitem);


}); // Close the "slid" function
    
})(jQuery); // Fully reference jQuery after this point.

